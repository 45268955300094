<template>
  <div class="record">
    <headbar :title="$t('铸币记录')"></headbar>

    <div class="invest">
      <van-list v-if="list.length > 0" v-model:loading="loading" :finished="finished" :finished-text="$t('没有更多了')"
        :immediate-check="false" offset="10" @load="onLoad">
        <div class="invest-item" v-for="item in list" :key="item.id">
          <div class="cell">
            <div>{{ $t('铸币消耗USD') }}：{{ item.usd }}</div>
            <div>{{ abbr(item.userid, 4, 4) }}</div>
          </div>
          <div class="cell">
            <div>{{ $t('铸币LBD数量') }}：{{ item.lbd }}</div>
            <div>{{ item.createTime }}</div>
          </div>
          <!-- <div class="cell-btn" v-if="item.shouyiTotal < item.amountUsdt && item.state == 0 || item.state == 1">
            <div class="btn" @click="tuikuan(item.id)">退款</div>
          </div> -->
        </div>
      </van-list>

      <empty v-if="list.length == 0"></empty>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { List } from "vant";
import CustomComponent from "@/components/CustomComponent";

export default {
  name: '',
  components: {
    [List.name]: List,
  },
  setup() {
    return {
      us: ref(null),
      list: ref([]),
      pageIndex: ref(1),
      loading: ref(false),
      finished: ref(false),
    }
  },
  created() {
    this.us = JSON.parse(this.$utils.getloc("us"))
    // console.log(this.us)
    if (this.us) {
      this.getdata()
    }
  },
  methods: {
    onLoad() {
      this.loading = true
      this.pageIndex++
      this.getdata()
    },
    getdata() {
      let _this = this
      _this.$request.post(
        "api/TokenZhubi/List",
        {
          userid: _this.us.userid,
          pageIndex: _this.pageIndex,
          pageSize: 10,
          state: 99
        },
        (res) => {
          _this.loading = false
          if (res.data.code == 0) {
            _this.$q.dialog({
              component: CustomComponent,
              componentProps: {
                messages: [res.data.msg],
                persistent: true,
              }
            });
          }
          let data = res.data.data
          if (data.length == 0) {
            _this.finished = true
          } else {
            _this.finished = false
          }
          // console.log(data)
          _this.list.push(...data)
        }
      )
    },
    abbr(val, start, end) {
      if (val && val.length > 20) {
        let newVal = val.replace(
          val.substring(start, val.length - end),
          "****"
        )
        return newVal
      } else {
        return val
      }
    },
  }
}
</script>

<style scoped>
.record {
  padding: 10px;
  min-height: 100vh;
  background-color: #080535
}

.invest-item {
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 10px;
  color: #fff;
  background-color: rgb(45, 38, 56);
}

.cell {
  padding: 2px 0;
  display: flex;
  justify-content: space-between;
}

.cell-btn {
  display: flex;
  justify-content: flex-end;
}

.btn {
  margin-top: 8px;
  padding: 3px 15px;
  color: #fff;
  font-size: 14px;
  text-align: center;
  background: linear-gradient(90deg, #f56ef9 0, #8a6dff);
  border-radius: 6px;
}
</style>